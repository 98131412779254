
<template>
    <svg :width="size + 'px'" :fill="color" viewBox="0 0 101 89.264">
      <path id="Pick_up_icon" data-name="Pick up icon" d="M9387.68,1541.813c-.808-.723-19.967-17.958-19.967-34.638a21.144,21.144,0,1,1,42.287,0c0,16.685-19.153,33.915-19.968,34.638a1.763,1.763,0,0,1-2.353,0Zm-10.561-34.789a11.739,11.739,0,1,0,11.739-11.74A11.741,11.741,0,0,0,9377.119,1507.024Zm-60.477,25.883a6.653,6.653,0,0,1-6.643-6.653v-69.377a2.869,2.869,0,0,1,2.869-2.869H9339.7v25.437l9.744-6.584,9.7,6.575V1454h26.828a2.87,2.87,0,0,1,2.869,2.872v23.734a26.563,26.563,0,0,0-26.022,22.175h-28.261a.433.433,0,0,0-.4.4v6.87a.433.433,0,0,0,.4.4h28.1a33.337,33.337,0,0,0,.971,4.9H9349.81a.433.433,0,0,0-.4.4v6.912a.433.433,0,0,0,.4.4l16.8,0a65.1,65.1,0,0,0,6.027,9.827Z" transform="translate(-9309.5 -1453.499)" fill="#a5b2b7" stroke="rgba(0,0,0,0)" stroke-width="1"/>
    </svg>
</template>

<script>
export default {
    name: 'iconPickups',
    components: {},
    props: {
        size: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: ''
        }
    }
}
</script>

<style scoped>
</style>

